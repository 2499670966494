import React, { useState, useEffect } from "react";
import * as post from "../templates/post.js";
import * as page from "../templates/page.js";

const postTypes = {
  post,
  page
};

const Preview = () => {
  console.info("PREVIEW MODE"); //eslint-disable-line
  const [data, setData] = useState({});
  useEffect( () => {
    try {
      const domain = atob(window.location.search.slice(1));
      window.addEventListener("message", (event) => {
        if (domain !== (event.origin)) {
          return;
        }
        setData(event.data);
      }, false);
      window.parent.postMessage("ready", domain);
    } catch {
      console.warn("couldn't find origin for preview setup"); // eslint-disable-line
    }
  }, []);
  return (
    <>
      { (data.postType && postTypes[data.postType]) ?
        postTypes[data.postType].default(data)
        : (<></>)
      }
    </>
  );
};

export default Preview;
